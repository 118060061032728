.heading-1 {
    font-weight: bold;
    font-size: 36px;
}

.external-link {
    cursor: pointer;
    font-size: 24px;
}

.external-link:hover {
    color: blue;
    transition: 0.4s;
}