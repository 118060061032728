.nav {
    display: flex;
    flex-direction: row;
    /* justify-items: center; */
    justify-content: space-around;
    background-color: lightblue;
    height: 60px;
}


.nav-field {
    padding: 10px;
    font-size: 24px;
    width: 270px;
    /* background-color: pink; */
}

.nav-field-dropdown {
    margin-top: 20px;
    font-size: 20px;
    display: none;
    font-weight: normal;
}

.nav-field:hover {
    font-size: 26px;
    transition: 0.05s;
    cursor: pointer;
    font-weight: bold;
}

.nav-field:hover .nav-field-dropdown {
    display: block;
}

ul li {
    list-style-type: none;
}

ul li:hover {
    cursor: pointer;
    font-size: 22px;
    transition: 0.05s;
    font-weight: bold;
}